import { IField } from '../../data-models/field2.data-model';
import { IMetricsDataModel } from '../../data-models/metrics.data-model';
import { CalculatedMetricsDataModel } from '../../pages/PortfolioOverview/components/OverviewTable/CompanyMetricsCalculator';
import { customFieldsListLoadable } from '../../services/state/AdminPanel/CustomFieldsStateJ';
import { kpiConfigByKeyMapAtomLoadable } from '../../services/state/KPIConfigState';
import { getForesightStore } from '../../util/jotai-store';
import { isNumber, sortNumbers } from '../../util/numericUtils';

type NumericalKeys<T> = {
  [K in keyof T]: T[K] extends number | null | undefined ? K : never;
}[keyof T];

type DataTypeWithCompanyId<T> = T & { companyId: number };

export type MetricsScoreContext = {
  capEfficiencyPercentiles: Map<number, number | undefined>;
  investmentAgePercentiles: Map<number, number | undefined>;
  l3mGrossProfitPercentiles: Map<number, number | undefined>;
  moicPercentiles: Map<number, number | undefined>;
  percentOfFundPercentiles: Map<number, number | undefined>;
  revenueGrowthPercentilesByQuartiles: Map<number, number | undefined>;
};

export type KpiContext = {
  l3mEbitdaMax: number;
  values: KpiValues[];
};

export type KpiValues = {
  boardSentiment?: string;
  boardSentimentL3m?: string;
  companyId: number;
  cashBalance1m?: number;
  cashBalanceL3m?: number;
  grossProfitL3m?: number;
  ebitdaL3m?: number;
  totalRevenueL3m?: number;
} & IMetricsDataModel['kpiDataExtra'];

export type Quartile = 0 | 1 | 2 | 3 | 4;

const PRECISION = 2;
const PRECISION_FACTOR = Math.pow(10, PRECISION);

export function getContextData(metrics: CalculatedMetricsDataModel[]) {
  const store = getForesightStore();
  const customFieldsLoadable = store.get(customFieldsListLoadable);
  const customFields = customFieldsLoadable.state === 'hasData' ? customFieldsLoadable.data : [];
  const customFieldsByDisplayName = customFields.reduce((res, field) => {
    res.set(field.displayName, field);
    return res;
  }, new Map<string, IField<unknown>>());
  const kpisByKeyLoadable = store.get(kpiConfigByKeyMapAtomLoadable);
  const kpisById =
    kpisByKeyLoadable.state === 'hasData' ? kpisByKeyLoadable.data : new Map<string, IField<unknown>>();

  return metrics.reduce(
    (acc, metric) => {
      const values: KpiValues = { companyId: metric.companyId, ...(metric.kpiDataExtra ?? {}) };
      if (kpisById.has('cashBalance')) {
        const kpiId = kpisById.get('cashBalance')!.id;
        values.cashBalanceL3m = metric.kpiData?.[kpiId]?.['L3M']?.actual?.value;
        values.cashBalance1m = metric.cashBalance;
      }
      if (kpisById.has('ebitda')) {
        const kpiId = kpisById.get('ebitda')!.id;
        values.ebitdaL3m = metric.kpiData?.[kpiId]?.['L3M']?.actual?.value;
        acc.l3mEbitdaMax = Math.max(acc.l3mEbitdaMax, isNumber(values.ebitdaL3m) ? values.ebitdaL3m : 0);
      }
      if (kpisById.has('grossProfit')) {
        const kpiId = kpisById.get('grossProfit')!.id;
        values.grossProfitL3m = metric.kpiData?.[kpiId]?.['L3M']?.actual?.value;
      }
      if (kpisById.has('totalRevenue')) {
        const kpiId = kpisById.get('totalRevenue')!.id;
        values.totalRevenueL3m = metric.kpiData?.[kpiId]?.['L3M']?.actual?.value;
      }
      if (kpisById.has('boardSentiment')) {
        const kpiId = kpisById.get('boardSentiment')!.id;
        values.boardSentimentL3m = metric.kpiData?.[kpiId]?.['L3M']?.actual?.value as unknown as string;
      }
      if (customFieldsByDisplayName.has('Board Sentiment')) {
        const customFieldId = customFieldsByDisplayName.get('Board Sentiment')!.id;
        values.boardSentiment = metric.company?.customData?.[customFieldId] as string;
      }

      acc.values.push(values);

      return acc;
    },
    { l3mEbitdaMax: 0, values: [] } as KpiContext
  );
}

export function calculateFieldPercentiles<DataType extends { companyId: number }>(
  metrics: DataType[],
  fieldKey: NumericalKeys<DataType>,
  excludeInvalid = true // exclude nulls / undefined / NaN
) {
  if (!fieldKey) {
    return new Map<number, number>();
  }

  const _metrics = excludeInvalid ? metrics.filter((metric) => isNumber(metric[fieldKey])) : metrics;

  if (_metrics.length === 0) {
    return new Map<number, number>();
  }
  if (_metrics.length === 1) {
    const { companyId, [fieldKey]: value } = metrics[0];
    return new Map<number, number>([[companyId, value as number]]);
  }

  const percentilesByCompanyId = new Map<number, number | undefined>();
  const fieldCounts = new Map<number, number>();

  // Calc frequency of each value & sort unique
  const valueToFrequency = _metrics.reduce((res, metric) => {
    if (isNumber(metric[fieldKey])) {
      res.set(metric[fieldKey], (fieldCounts.get(metric[fieldKey]) || 0) + 1);
    }

    return res;
  }, new Map<number, number>());
  const sortedUniqueValues = Array.from(valueToFrequency.keys()).sort((a, b) => sortNumbers(a, b));

  // Precompute cumulative counts
  const cumulativeCounts = new Map<number, number>();
  let cumCount = 0;

  for (const value of sortedUniqueValues) {
    cumulativeCounts.set(value, cumCount);
    cumCount += valueToFrequency.get(value)!;
  }

  // Calculate the percentiles
  const totalCount = sortedUniqueValues.length;
  _metrics.forEach((metric) => {
    if (!isNumber(metric[fieldKey])) {
      percentilesByCompanyId.set(metric.companyId, undefined);
    } else {
      const value = metric[fieldKey];
      const below = cumulativeCounts.get(value)!;
      const equal = valueToFrequency.get(value)!;
      // let percentile = ((below + 0.5 * equal) / totalCount) * 100;

      if (equal >= totalCount) {
        percentilesByCompanyId.set(metric.companyId, undefined);
      } else {
        let percentile = below / (totalCount - equal);
        percentile = Math.round(percentile * PRECISION_FACTOR) / PRECISION_FACTOR;
        percentilesByCompanyId.set(metric.companyId, percentile);
      }
    }
  });

  return percentilesByCompanyId;
}

export function calculateFieldQuartiles<T>(
  metrics: DataTypeWithCompanyId<T>[],
  fieldKey: NumericalKeys<DataTypeWithCompanyId<T>>
): Map<number, Quartile> {
  if (!fieldKey || metrics.length === 0) {
    return new Map();
  }

  const quartilesByCompanyId = new Map<number, Quartile>();
  const metricsSortedByField = metrics.toSorted((m1, m2) =>
    sortNumbers(m1[fieldKey] as number, m2[fieldKey] as number)
  );
  const quartiles = getQuartileValues(metricsSortedByField, fieldKey);

  metrics.forEach((metric) => {
    const value = metric[fieldKey] as number;
    let quartile: Quartile;

    if (value === quartiles[0]) {
      quartile = 0;
    } else if (value <= quartiles[1]) {
      quartile = 1;
    } else if (value <= quartiles[2]) {
      quartile = 2;
    } else if (value <= quartiles[3]) {
      quartile = 3;
    } else {
      quartile = 4;
    }

    quartilesByCompanyId.set(metric.companyId, quartile);
  });

  return quartilesByCompanyId;
}

export function getQuartileValues<T>(
  values: DataTypeWithCompanyId<T>[],
  fieldKey: NumericalKeys<DataTypeWithCompanyId<T>>
): Record<Quartile, number> {
  const sortedValues = values
    .filter((metric) => isNumber(metric[fieldKey]))
    .toSorted((a, b) => {
      return sortNumbers(a[fieldKey] as number, b[fieldKey] as number);
    });

  if (sortedValues.length < 2) {
    const baseValue = sortedValues.at(0)?.[fieldKey] ?? 0;
    return { 0: baseValue, 1: baseValue, 2: baseValue, 3: baseValue, 4: baseValue } as Record<
      Quartile,
      number
    >;
  }

  return {
    0: sortedValues.at(0)?.[fieldKey],
    1: getQuartile(sortedValues, fieldKey, 0.25),
    2: getQuartile(sortedValues, fieldKey, 0.5),
    3: getQuartile(sortedValues, fieldKey, 0.75),
    4: sortedValues.at(sortedValues.length - 1)?.[fieldKey],
  } as Record<Quartile, number>;
}

function getQuartile<T>(
  sortedArray: DataTypeWithCompanyId<T>[],
  fieldKey: NumericalKeys<DataTypeWithCompanyId<T>>,
  q: number
) {
  const pos = (sortedArray.length - 1) * q;
  const base = Math.floor(pos);
  const rest = pos - base;

  if (sortedArray[base + 1] !== undefined) {
    const leftValue = sortedArray[base][fieldKey] as number;
    const rightValue = sortedArray[base + 1][fieldKey] as number;

    return leftValue * (1 - rest) + rightValue * rest;
  } else {
    return sortedArray[base][fieldKey] as number;
  }
}

function calculateRevenueGrowthPercentilesByQuartiles(
  kpiData: KpiValues[],
  quartiles: Map<number, Quartile>
) {
  const l3mRevenueGrowthYoyByQuartile = kpiData.reduce((acc, kpi) => {
    const quartile = quartiles.get(kpi.companyId)!;

    if (!acc.has(quartile)) {
      acc.set(quartile, []);
    }

    acc.get(quartile)!.push(kpi);

    return acc;
  }, new Map<Quartile, KpiValues[]>());

  return Array.from(l3mRevenueGrowthYoyByQuartile.entries())
    .map(([_quartile, kpiValues]) => {
      return calculateFieldPercentiles(kpiValues, 'revenueGrowthL3mYoy');
    })
    .reduce((acc, percentilesByCompanyId) => {
      percentilesByCompanyId.forEach((percentile, companyId) => {
        acc.set(companyId, percentile);
      });
      return acc;
    }, new Map<number, number>());
}

export function calculatePercentiles(
  metrics: CalculatedMetricsDataModel[],
  kpiData: KpiValues[]
): MetricsScoreContext {
  const investmentAgePercentiles = calculateFieldPercentiles(
    metrics.filter((metric) => {
      return metric.investmentAge > 3;
    }),
    'investmentAge'
  );

  const moicPercentiles = calculateFieldPercentiles(metrics, 'moic');
  const percentOfFundPercentiles = calculateFieldPercentiles(metrics, 'fmv');
  const l3mGrossProfitPercentiles = calculateFieldPercentiles(kpiData, 'grossProfitL3m');
  const revenueGrowthQuartiles = calculateFieldQuartiles(kpiData, 'totalRevenueL3m');
  const revenueGrowthPercentilesByQuartiles = calculateRevenueGrowthPercentilesByQuartiles(
    kpiData,
    revenueGrowthQuartiles
  );
  const capEfficiencyPercentiles = calculateFieldPercentiles(kpiData, 'capEfficiency');

  return {
    capEfficiencyPercentiles,
    investmentAgePercentiles,
    l3mGrossProfitPercentiles,
    moicPercentiles,
    percentOfFundPercentiles,
    revenueGrowthPercentilesByQuartiles,
  };
}

const FMV_WEIGHT = 7;
export function fmvScore(metrics: IMetricsDataModel, context: MetricsScoreContext) {
  const percentile = context.percentOfFundPercentiles.get(metrics.companyId);
  return percentile != null ? percentile * FMV_WEIGHT : percentile;
}

const MOIC_WEIGHT = 3;
export function moicScore(metrics: IMetricsDataModel, context: MetricsScoreContext) {
  if (!isNumber(metrics.moic)) {
    return Number.NaN;
  }

  if (metrics.moic < 1) {
    return 0;
  }
  if (metrics.moic === 1) {
    if (metrics.investmentAge < 3) {
      return MOIC_WEIGHT / 2;
    } else {
      const investmentAgePercentile = context.investmentAgePercentiles.get(metrics.companyId);
      return investmentAgePercentile != undefined ? (MOIC_WEIGHT / 2) * investmentAgePercentile : undefined;
    }
  }

  const moicPercentile = context.moicPercentiles.get(metrics.companyId);
  return moicPercentile != undefined ? MOIC_WEIGHT / 2 + (MOIC_WEIGHT / 2) * moicPercentile : undefined;
}

const CASH_FLOW_POS = 'CF Pos';
const CASH_RUNWAY_WEIGHT = 2;
export function cashRunwayScore(kpiData: KpiValues, l3mEbitdaMax: number) {
  const baseScore = ebitdaMonthsRunway(kpiData);

  if (isNumber(baseScore)) {
    if (baseScore <= 12) {
      return 0;
    } else if (baseScore <= 24) {
      return CASH_RUNWAY_WEIGHT / 2;
    } else if (baseScore <= l3mEbitdaMax) {
      return CASH_RUNWAY_WEIGHT;
    }
  } else if (baseScore === CASH_FLOW_POS) {
    return CASH_RUNWAY_WEIGHT;
  }
  return CASH_RUNWAY_WEIGHT / 2;
}

function ebitdaMonthsRunway(kpiData: KpiValues) {
  const { ebitdaL3m, cashBalance1m } = kpiData;
  if (ebitdaL3m == null || cashBalance1m == null) {
    return '';
  }

  if (ebitdaL3m === 0) {
    return '';
  }

  if (ebitdaL3m > 0) {
    return CASH_FLOW_POS;
  }

  return -cashBalance1m / (ebitdaL3m / 3);
}

const CAPITAL_EFFICIENCY_WEIGHT = 2;
export function capitalEfficiencyScore(metrics: IMetricsDataModel, context: MetricsScoreContext) {
  const totalRevenueNetChangeL6m = metrics.kpiDataExtra?.totalRevenueNetChangeL6m ?? Number.NaN;
  const metricL6m = metrics.kpiDataExtra?.metricL6m ?? Number.NaN;

  if (totalRevenueNetChangeL6m > 0 && metricL6m > 0) {
    return CAPITAL_EFFICIENCY_WEIGHT;
  } else if (totalRevenueNetChangeL6m < 0 && metricL6m > 0) {
    return CAPITAL_EFFICIENCY_WEIGHT / 2;
  } else if (totalRevenueNetChangeL6m < 0 && metricL6m < 0) {
    return 0;
  } else {
    const capEfficiencyPercentile = context.capEfficiencyPercentiles.get(metrics.companyId);
    return capEfficiencyPercentile ? capEfficiencyPercentile * CAPITAL_EFFICIENCY_WEIGHT : undefined;
  }
}

const GROSS_PROFIT_SCALE_WEIGHT = 2;
export function grossProfitScaleScore(metrics: IMetricsDataModel, context: MetricsScoreContext) {
  const defaultValue = GROSS_PROFIT_SCALE_WEIGHT / 2;
  const gpScalePercentile = context.l3mGrossProfitPercentiles.get(metrics.companyId);

  return gpScalePercentile !== undefined ? gpScalePercentile * GROSS_PROFIT_SCALE_WEIGHT : defaultValue;
}

const REVENUE_GROWTH_WEIGHT = 2;
export function revenueGrowthScore(metrics: IMetricsDataModel, context: MetricsScoreContext) {
  const defaultValue = REVENUE_GROWTH_WEIGHT / 2;
  const revenueGrowthQuartile = context.revenueGrowthPercentilesByQuartiles.get(metrics.companyId);

  return revenueGrowthQuartile !== undefined ? revenueGrowthQuartile * REVENUE_GROWTH_WEIGHT : defaultValue;
}

const BOARD_SENTIMENT_WEIGHT = 2;
const boardSentimentToScore = {
  Negative: 0,
  Neutral: BOARD_SENTIMENT_WEIGHT / 2,
  Positive: BOARD_SENTIMENT_WEIGHT,
};
export function boardSentimentScore(boardSentiment: string | undefined) {
  const defaultValue = REVENUE_GROWTH_WEIGHT / 2;

  return boardSentimentToScore[boardSentiment as keyof typeof boardSentimentToScore] ?? defaultValue;
}
