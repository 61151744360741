import { CashFlow } from '@webcarrot/xirr';
import { ColDef, ColGroupDef, IAggFunc, IAggFuncParams } from 'ag-grid-community';
import { IMetricsDataModel } from '../../../../../data-models/metrics.data-model';
import { MetricsTransactionDataModel } from '../../../../../schemas/MetricsTransaction.schema';
import { ColumnMeta } from '../../../../../types';
import { isObject } from '../../../../../util/object-util';
import { calculateXIRR } from '../../../providers/calculateXIRR';
import { FDIterator } from '../StandardIterators';

function runAggregationIterator<ReturnType, ValueType, ContextType = ValueType>(
  params: IAggFuncParams,
  iterator: FDIterator<ReturnType, ValueType, ContextType>
) {
  if (params.rowNode.leafGroup) {
    params.rowNode.childrenAfterFilter?.forEach((node) => {
      iterator.next(node.data);
    });
  } else {
    params.values.forEach((val) => {
      iterator.next(val);
    });
  }

  return iterator.value;
}

type OwnershipAggResult = { count: number } & Pick<IMetricsDataModel, 'ownerShipPercentage'>;
const ownershipIterator = (): FDIterator<
  OwnershipAggResult & { value: number | null },
  OwnershipAggResult
> => {
  let count = 0;
  let sum = 0;

  return {
    get value() {
      const ownerShipPercentage = count ? sum / count : null;

      return {
        count,
        ownerShipPercentage,
        value: ownerShipPercentage,
      };
    },
    next(data) {
      if (data.ownerShipPercentage) {
        count++;
        sum += data.ownerShipPercentage;
      }
    },
  };
};

type NumericalFields<T> = {
  [K in keyof T as T[K] extends number | null | undefined ? K : never]: T[K];
};

type MoicAggResult = Partial<NumericalFields<IMetricsDataModel>>;
export const moicIterator = (
  totalValueField: keyof NumericalFields<IMetricsDataModel>,
  investmentAmountField: keyof NumericalFields<IMetricsDataModel>
): FDIterator<MoicAggResult & { value: number | null }, MoicAggResult> => {
  let sumTotalValue = 0;
  let sumInvestmentAmount = 0;

  return {
    get value() {
      const moic = sumInvestmentAmount ? sumTotalValue / sumInvestmentAmount : null;

      return {
        [totalValueField]: sumTotalValue,
        [investmentAmountField]: sumInvestmentAmount,
        value: moic,
      };
    },
    next(data) {
      sumTotalValue += data[totalValueField] ?? 0;
      sumInvestmentAmount += data[investmentAmountField] ?? 0;
    },
  };
};

type IrrAggResult = Pick<IMetricsDataModel, 'fmv' | 'transactions'>;
export const irrIterator = (
  asOfDate = new Date()
): FDIterator<IrrAggResult & { value: number }, IrrAggResult> => {
  const groupCashflows: CashFlow[] = [];
  const groupedTransactions: MetricsTransactionDataModel[] = [];
  let fmv = 0;

  return {
    get value() {
      groupCashflows.push({
        date: asOfDate,
        amount: fmv,
      });
      const irr = calculateXIRR(groupCashflows);

      return {
        fmv,
        transactions: groupedTransactions,
        value: irr,
      };
    },
    next(data) {
      fmv += data.fmv;
      if (data.transactions && data.transactions.length > 0) {
        groupedTransactions.push(...data.transactions);
      }
      data.transactions?.forEach((t: MetricsTransactionDataModel) => {
        if (t.investmentAmount) {
          groupCashflows.push({
            date: new Date(t.transactionDate),
            amount: -1 * t.investmentAmount,
          });
        }
        if (t.distributions) {
          groupCashflows.push({
            date: new Date(t.transactionDate),
            amount: t.distributions,
          });
        }
      });
    },
  };
};

export const customAggFuncs: { [key: string]: IAggFunc } = {
  moic: (params: IAggFuncParams) =>
    runAggregationIterator(params, moicIterator('totalValue', 'amountInvested')),
  irr: (params: IAggFuncParams) => runAggregationIterator(params, irrIterator(params.context.asOfDate)),
  ownershipPercentage: (params: IAggFuncParams) => runAggregationIterator(params, ownershipIterator()),
  moicAggFunc: (params: IAggFuncParams) =>
    runAggregationIterator(params, moicIterator('totalValue', 'amountInvested')),
  unrealizedMOIC: (params: IAggFuncParams) =>
    runAggregationIterator(params, moicIterator('fmv', 'unrealizedValue')),
  realizedMOICWithEscrow: (params: IAggFuncParams) =>
    runAggregationIterator(params, moicIterator('realizedAmountWithEscrow', 'realizedValue')),
  irrAggFunc: (params: IAggFuncParams) =>
    runAggregationIterator(params, irrIterator(params.context.asOfDate)),
};

const aggNumericalComparator: ColDef['comparator'] = (a, b) => {
  const valueA = isObject(a) ? a.value : a;
  const valueB = isObject(b) ? b.value : b;

  if (valueA === valueB) return 0;
  if (valueA === null || valueA === undefined) return -1;
  if (valueB === null || valueB === undefined) return 1;
  return valueA - valueB;
};

const customAggFuncColumnsMap: Record<string, ColDef | ColGroupDef> = {
  moic: {
    aggFunc: 'moic',
    comparator: aggNumericalComparator,
  },
  unrealizedMOIC: {
    aggFunc: 'unrealizedMOIC',
    comparator: aggNumericalComparator,
    defaultAggFunc: 'unrealizedMOIC',
    allowedAggFuncs: ['unrealizedMOIC', 'avg'],
  },
  realizedMOICWithEscrow: {
    aggFunc: 'realizedMOICWithEscrow',
    comparator: aggNumericalComparator,
    defaultAggFunc: 'realizedMOICWithEscrow',
    allowedAggFuncs: ['realizedMOICWithEscrow'],
  },
  irr: {
    aggFunc: 'irr',
    allowedAggFuncs: ['irr'],
    comparator: aggNumericalComparator,
  },
  ownershipPercentage: {
    aggFunc: 'ownershipPercentage',
    enableValue: true,
    allowedAggFuncs: ['ownershipPercentage'],
    defaultAggFunc: 'ownershipPercentage',
    comparator: aggNumericalComparator,
  },
};

const defaultAggFunctions = ['sum', 'min', 'max', 'avg'];

export function getAggregationConfigs(column: ColumnMeta): Partial<ColDef | ColGroupDef> | null {
  if (!column.aggregation) return null;

  if (!column.aggFunc) {
    return {
      aggFunc: 'avg',
      enableValue: true,
      allowedAggFuncs: defaultAggFunctions,
    };
  }

  if (customAggFuncColumnsMap[column.aggFunc]) {
    return customAggFuncColumnsMap[column.aggFunc];
  }

  return {
    aggFunc: column.aggFunc,
    enableValue: true,
    allowedAggFuncs: defaultAggFunctions,
  };
}
