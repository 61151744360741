import { MoicAndIrrByDealLeadChart } from '../../pages/PortfolioOverview/components/ChartsSection/MoicAndIrrByDealLeadChart';
import { MostRecentRealizations } from '../../pages/PortfolioOverview/components/ChartsSection/MostRecentRealizations';
import { CHART_COMPONENTS } from '../../types';
import { GCPortfolioAssessmentChart } from './GCPortfolioAssessmentChart';

export function getClientMetricsCharts() {
  return {
    [CHART_COMPONENTS.MOST_RECENT_REALIZATIONS]: MostRecentRealizations,
    [CHART_COMPONENTS.MOIC_AND_IRR_BY_DEAL_LEAD]: MoicAndIrrByDealLeadChart,
    [CHART_COMPONENTS.GC_PORTFOLIO_ASSESSMENT]: GCPortfolioAssessmentChart,
  };
}

export function getClientMetricsChartOptions() {
  return [
    { id: CHART_COMPONENTS.GC_PORTFOLIO_ASSESSMENT, value: 'Portfolio Assessment' },
    { id: CHART_COMPONENTS.MOST_RECENT_REALIZATIONS, value: 'Most Recent Realizations' },
    { id: CHART_COMPONENTS.MOIC_AND_IRR_BY_DEAL_LEAD, value: 'MOIC and IRR by Original Deal Lead' },
  ];
}
