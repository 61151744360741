import { useRecoilValueLoadable } from 'recoil';
import { Alert } from '@mui/material';
import { MostRecentDataChart } from '../../../../components/Charts/Leaderboard/MostRecentRounds/MostRecentDataChart';
import { Loader } from '../../../../components/Loader/Loader';
import { CHART_COMPONENTS } from '../../../../types';
import { useMostRecentRealizations } from '../../hooks/useMostRecentRealizations';
import { financeDashboardState } from '../../../Finance2/state/FinanceState';

export function MostRecentRealizations() {
  const dashboardState = useRecoilValueLoadable(financeDashboardState);
  const state = dashboardState.state;
  const data = useMostRecentRealizations(dashboardState.valueMaybe());

  if (state === 'loading') {
    return <Loader />;
  }
  if (state == 'hasError') {
    return <Alert severity='error'>Failed to load data</Alert>;
  }

  return (
    <MostRecentDataChart
      id={CHART_COMPONENTS.MOST_RECENT_REALIZATIONS}
      width={640}
      data={data}
      columnHeaders={['Company', 'Deal Lead', 'Date', 'Received Amount', 'Realized G/L']}
    />
  );
}
