import { ICompanyDataModel } from '../../../data-models/company.data-model';
import { IMetricsDataModel } from '../../../data-models/metrics.data-model';
import { format } from '../../../pages/PortfolioOverview/services/kpi/kpi-formatter';
import { KpiConfig, KPI_COMPONENTS, KpiConfigMeta, KpiCalculationParams, Kpi } from '../../../types';

export class ProfitableExitsConfig implements KpiConfig {
  private id = KPI_COMPONENTS.PROFITABLE_EXITS as const;
  private title = 'Profitable Exits';
  private type = 'integer';

  private getDistinctOriginalDeals(metrics: IMetricsDataModel[], companies: Map<number, ICompanyDataModel>) {
    const dedupedOriginalDeals = new Set<string>();
    for (const m of metrics) {
      const company = companies.get(m.companyId);
      if (!company?.originalDeal?.length) continue;
      dedupedOriginalDeals.add(company.originalDeal.toSorted((a, b) => a.localeCompare(b)).join(','));
    }
    return dedupedOriginalDeals.size;
  }

  getMeta(): KpiConfigMeta {
    return {
      id: this.id,
      title: this.title,
      type: this.type,
    };
  }

  calculate({ data, companies }: KpiCalculationParams): Kpi {
    const value = this.getDistinctOriginalDeals(
      data?.metrics?.filter(
        ({ realizedGLWithEscrow }) => realizedGLWithEscrow != null && realizedGLWithEscrow > 1
      ) ?? [],
      companies ?? new Map()
    );

    return {
      ...this.getMeta(),
      value,
      formatted: format(value, this.type),
    };
  }
}
