import { IMetricsDataModel } from '../../data-models/metrics.data-model';
import { CalculatedMetricsDataModel } from '../../pages/PortfolioOverview/components/OverviewTable/CompanyMetricsCalculator';
import {
  afterProcessTransactions as afterProcessTransactionsBase,
  createMetricTotalProcessor,
  createTransactionsProcessor,
} from '../default/metrics';
import { IAfterProcessTransactionsContext, ITransactionProcessResult } from '../default/metrics.type';
import { customLabelsCalculator, realizedGLWithEscrow, realizedMOICWithEscrow } from './metric-formulas';
import {
  boardSentimentScore,
  calculatePercentiles,
  capitalEfficiencyScore,
  cashRunwayScore,
  fmvScore,
  getContextData,
  grossProfitScaleScore,
  moicScore,
  revenueGrowthScore,
} from './metrics-scores';

function afterProcessTransactions(
  metric: IMetricsDataModel & ITransactionProcessResult,
  context: IAfterProcessTransactionsContext
) {
  const res = afterProcessTransactionsBase(metric, context);
  const includedFundsIds = new Set(Array.from(metric.includedFunds.keys()));

  return {
    ...res,
    realizedGLWithEscrow: realizedGLWithEscrow(metric),
    realizedMOICWithEscrow: realizedMOICWithEscrow(metric),
    seedCore: customLabelsCalculator(metric, includedFundsIds),
  };
}

export type GCMetricsDataModel = CalculatedMetricsDataModel & {
  boardSentimentScore?: number;
  capitalEfficiencyScore?: number;
  cashRunwayScore: number;
  fmvScore?: number;
  grossProfitScaleScore: number;
  moicScore?: number;
  revenueGrowthScore: number;
  totalPerformanceScore: number;
  totalValueScore: number;
};

function calculateMetricsOnFilteredData(_metrics: GCMetricsDataModel[], filteredData: IMetricsDataModel[]) {
  const metrics = _metrics.map((m) => ({ ...m }));
  const includedCompanyIds = filteredData.reduce((acc, metric) => {
    acc.add(metric.companyId);
    return acc;
  }, new Set<number>());
  const includedMetrics = metrics.filter((metric) => includedCompanyIds.has(metric.companyId));
  const contextData = getContextData(includedMetrics);
  const percentiles = calculatePercentiles(includedMetrics, contextData.values);

  includedMetrics.forEach((metric, index) => {
    metric.boardSentimentScore = boardSentimentScore(contextData.values[index]?.boardSentiment);
    metric.capitalEfficiencyScore = capitalEfficiencyScore(metric, percentiles);
    metric.cashRunwayScore = cashRunwayScore(contextData.values[index], contextData.l3mEbitdaMax);
    metric.fmvScore = fmvScore(metric, percentiles);
    metric.grossProfitScaleScore = grossProfitScaleScore(metric, percentiles);
    metric.moicScore = moicScore(metric, percentiles);
    metric.revenueGrowthScore = revenueGrowthScore(metric, percentiles);
    metric.totalPerformanceScore =
      (metric.boardSentimentScore ?? 0) +
      (metric.capitalEfficiencyScore ?? 0) +
      (metric.cashRunwayScore ?? 0) +
      (metric.grossProfitScaleScore ?? 0) +
      (metric.revenueGrowthScore ?? 0);
    metric.totalValueScore = (metric.fmvScore ?? 0) + (metric.moicScore ?? 0);
  });

  return { metrics, filteredMetrics: metrics };
}

export {
  createTransactionsProcessor,
  createMetricTotalProcessor,
  afterProcessTransactions,
  calculateMetricsOnFilteredData,
};
