import {
  DatasetComponentOption,
  GridComponentOption,
  init as echartsInit,
  LegendComponentOption,
  ScatterSeriesOption,
  TooltipComponentOption,
} from 'echarts';
import { ComposeOption, ECharts } from 'echarts/core';
import { CallbackDataParams } from 'echarts/types/dist/shared';
import { useAtomValue } from 'jotai';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useValuationBridge } from '../../components/Charts/Barchart/hooks/useValuationBridge';
import { Chart, ChartSizes } from '../../components/Charts/chart-utils';
import { ChartTitleWrapper } from '../../components/Charts/ChartTitleWrapper';
import { ChartWrapper } from '../../components/Charts/ChartWrapper/ChartWrapper';
import { IconTypes } from '../../components/Icon';
import { useIsInViewport } from '../../hooks/useIsInViewport';
import { metricsFromFilteredDataAtom } from '../../pages/PortfolioOverview/state/MetricsState';
import { colors } from '../../theme/colors';
import { CHART_COMPONENTS } from '../../types';
import { FMT } from '../../util/formatter-service';
import { GCMetricsDataModel } from './metrics';

export function GCPortfolioAssessmentChart() {
  const gcData = useAtomValue(metricsFromFilteredDataAtom) as GCMetricsDataModel[];

  const chartData = gcData.map((metric) => {
    const { company, companyId, totalPerformanceScore, totalValueScore } = metric;

    return { name: company?.name ?? companyId.toString(), totalPerformanceScore, totalValueScore };
  });

  return <ScatterPlot chartData={chartData} />;
}

export type ECScatterOption = ComposeOption<
  | DatasetComponentOption
  | GridComponentOption
  | LegendComponentOption
  | ScatterSeriesOption
  | TooltipComponentOption
>;

type ScoreData = { name: string; totalPerformanceScore: number; totalValueScore: number };

interface ScatterPlotProps {
  chartData?: ScoreData[];
}

function ScatterPlot({ chartData }: ScatterPlotProps) {
  const data = useValuationBridge();
  const [widthState, setWidthState] = useState<string | number>(640);
  const [isHovering, setIsHovering] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [size, setSize] = useState('1 Screen');
  const chartContainer = useRef<HTMLDivElement | null>(null);
  const scatterChart = useRef<ECharts | null>(null);
  const isInViewport = useIsInViewport(
    {
      threshold: 0.6,
    },
    chartContainer
  );

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setShowMore(false);
    setIsHovering(false);
  };

  useLayoutEffect(() => {
    const updateSize = () => {
      setWidthState(ChartSizes[size as keyof typeof ChartSizes]);
    };

    updateSize();
  }, [size]);

  useEffect(() => {
    const container = chartContainer.current;

    if (!container) {
      return;
    }

    if (!scatterChart.current) {
      scatterChart.current = echartsInit(container) as unknown as ECharts;
    }

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.contentBoxSize) {
          scatterChart.current!.resize();
        }
      }
    });

    resizeObserver.observe(container);

    return () => {
      if (container) {
        resizeObserver.unobserve(container);
      }
    };
  }, [chartContainer]);

  useEffect(() => {
    if (!(scatterChart.current && isInViewport)) {
      return;
    }

    const data = chartData?.map((item) => ({
      name: item.name,
      value: [item.totalPerformanceScore, item.totalValueScore],
    }));

    const options: ECScatterOption = {
      grid: {
        top: 15,
        right: 20,
        bottom: 25,
        left: 25,
      },
      tooltip: {
        trigger: 'item',
        formatter: function (params) {
          const { name, value } = (params as CallbackDataParams).data as {
            name: string;
            value: [number, number];
          };
          return `${name}<br>Performance: ${FMT.format('number1dp', value[0])}<br>Value: ${FMT.format('number1dp', value[1])}`;
        },
      },
      xAxis: {
        interval: 1,
        max: 10,
        min: 0,
        name: 'Performance',
        nameLocation: 'middle',
      },
      yAxis: {
        interval: 1,
        max: 10,
        min: 0,
        name: 'Value',
        nameLocation: 'middle',
      },
      series: [
        {
          data,
          emphasis: {
            label: {
              show: true,
              fontWeight: 'bold',
              backgroundColor: 'white',
              borderWidth: 1,
              borderColor: 'black',
              padding: [2, 2],
            },
            itemStyle: {
              color: colors.critical[60],
              opacity: 1,
            },
          },
          label: {
            show: true,
            formatter: '{b}', // Display the name of the point
            position: 'right',
          },
          markLine: {
            data: [{ xAxis: 5 }, { yAxis: 5 }],
            lineStyle: {
              color: colors.critical[60],
              type: 'solid',
            },
            silent: true,
          },
          name: 'Portfolio Assessment',
          symbolSize: 10,
          type: 'scatter',
          itemStyle: {
            color: colors.primary[30],
          },
        },
      ],
    };

    scatterChart.current.setOption(options, true);
  }, [chartData, data, isInViewport]);

  return (
    <ChartWrapper
      id={CHART_COMPONENTS.VALUATION_BRIDGE}
      onMouseLeave={handleMouseLeave}
      onMouseEnter={handleMouseEnter}
      width={widthState}
      dataTestid={'line-chart'}
    >
      <ChartTitleWrapper
        isHovering={isHovering}
        title={'Portfolio Assessment'}
        showMore={showMore}
        setShowMore={setShowMore}
        id={CHART_COMPONENTS.GC_PORTFOLIO_ASSESSMENT}
        icon={IconTypes.FINANCE}
        handleSizeChange={setSize}
        size={size}
        refProp={chartContainer}
      />
      <Chart width={'100%%'} ref={chartContainer} />
    </ChartWrapper>
  );
}
